<template>
  <div id="app" @mousemove="handleMouseMove">
    <div>
      <div class="heard" v-show="isshow">
        <domeHeard></domeHeard>
      </div>
      
      <div class="contsss" :style="{height: boxHeight }"  @wheel="nWheelScroll">
       <router-view></router-view>
      </div>
  
 
       
      
    </div>
  </div>
</template>

<script>
import domeHeard from "@/components/domeheard.vue";
export default {
  components: {
    domeHeard,
  },
  name: "App",
  data(){
    return{
      isshow:true,
      boxHeight:"calc(100vh - 150px)",
    }
  },
  watch:{
    isshow(){
      if(this.isshow){
        this.boxHeight = "calc(100vh - 150px)";
      }else{
        this.boxHeight = "100vh";
      }
    }

  },
  mounted(){
    const scrollContainer = document.querySelector('.content');  
    // console.log(scrollContainer)
    scrollContainer.style.scrollbarWidth = 'none'
  
  },
  methods: {  
    nWheelScroll(event) {  
      // 获取滚轮滑动的方向和距离  
      const delta = event.deltaY; // 正值表示向下滚动，负值表示向上滚动  
      const scrollDistance = Math.abs(delta); // 滚动的距离  
      // console.log('Scroll direction:', delta > 0 ? 'Down' : 'Up');
      if(delta>0){
        this.isshow = false;
      } else if(delta<0){
        this.isshow = true;
      }
      // console.log('Scroll distance:', scrollDistance);  
    } ,
    handleMouseMove(event) {  
      // 获取鼠标在窗口中的位置  
      const clientX = event.clientX; // 鼠标在窗口上的水平坐标  
      const clientY = event.clientY; // 鼠标在窗口上的垂直坐标  
      // console.log('鼠标在窗口中的位置：', clientX, clientY ); 
      if(clientY <4){
        this.isshow = true;
      } 
    },   
  }  
};
</script>

<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app{
  width: 100vw;
  height: 100vh;
  background: #f0f2f5;
}
.box{
  width: 100%;
  height: 100%;
  background: #fff;
}
.heard{
  width:100%;
  padding-bottom: 0px !important;
  
}
 .el-main {
    padding: 0px !important;
}
.contsss {
  padding: 0 ;
  background-color:#FFF ;
  overflow-y: auto; 
  scrollbar-width: none; 
  -ms-overflow-style: none !important; /* IE/Edge */
   scrollbar-width: none !important;
}
::-webkit-scrollbar {
    width: 0 !important; /* Chrome Safari Opera */
}

</style>
