<template>
  <div>
    <!-- <div class="heardbox">
      <div class="heardbox_content">
        <div class="namelogo" style="color: #fff">中浩xxxx公司</div>
        <div
          @click="submitForm(item)"
          :class="item.id === activeIndex ? 'cssactive' : 'heardbox_item'"
          v-for="(item, index) in liexList"
          :key="index"
        >
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div> -->

    <div class="heardboxs">
      <div class="heardbox_contents">
        <div class="namelogo" style="color: #000">中浩创达汽车租赁</div>
        <div class="namelogoA" style="color: #000">ZHONGHAOCHUANGDA</div>
        <div
          @click="submitForm(item)"
          :class="item.id === activeIndex ? 'cssactive' : 'heardbox_itema'"
          v-for="(item, index) in liexList"
          :key="index"
        >
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "domeHeard",
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      liexList: [
        { id: "1", name: "公司介绍", path: "/" },
        // { id: "2", name: "限时活动", path: "/bianji" },
        { id: "3", name: "服务案例", path: "/fuwu" },
        { id: "4", name: "产品展示", path: "/shopping" },
        { id: "5", name: "关于我们", path: "/guanyu" },
      ],
      arr: [],
      obj: {},
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      if (this.$route.path !== keyPath[0]) {
        // console.log(keyPath[0]);
        this.$router.push({ path: keyPath[0] });
      }
    },
    submitForm(row) {
      this.activeIndex = row.id;
      window.localStorage.setItem("NavigationID", row.id);

      if (row.path) {
        this.$router.push({ path: row.path });
      }
    },
    receiveMessage(val) {
        
        console.log(val) // '数据内容'
        window.localStorage.setItem("NavigationID", val);
        this.activeIndex = val;
      }
    
  },

  mounted() {
    // console.log(this.$route);
    this.$bus.$on('eventBus', this.receiveMessage)
  },
  created() {
    if (window.localStorage.getItem("NavigationID")) {
      this.activeIndex = window.localStorage.getItem("NavigationID");
    } else {
      this.activeIndex = "1";
      this.$router.push("/");
    }
  },
};
</script>

<style>
/* 第一个头部列表 */
/* .heardbox {
  width: 100%;
  height: 80px;
  padding-top: 2.5px;
  box-shadow: 2px 2px 4px 0 #000000;
  background-color: rgba(25, 25, 25, 1);
}
.heardbox_content {
  display: flex;
  justify-content: center;
  height: 80px;
  background-color: rgba(25, 25, 25, 1);
}
.heardbox_item {
  width: 120px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  background: linear-gradient();
}
.cssactive {
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  background: linear-gradient();
  background-color: rgba(49, 139, 224, 0.726);
}
.namelogo {
  height: 80px;
  line-height: 80px;
  position: absolute;
  left: 1%;
  font-size: 28px;
  font-family: sans-serif;
  color: #fff;
}
.heardbox_item:hover {
  background-color: rgba(49, 139, 224, 0.726);
}
.tiao {
  width: 100%;
  height: 10px;
  background-color: rgba(49, 139, 224, 0.726);
} */
/* 第二个头部列表 */
.heardboxs {
  width: 100%;
  height: 150px;
  padding-top: 76px;
  box-shadow: 2px 2px 4px 0 #000000;
  background-color: rgb(250, 250, 250);
}
.heardbox_contents {
  display: flex;
  justify-content: right;
  height: 150px;
  background-color: rgb(250, 250, 250);
}
.heardbox_itema {
  width: 120px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  color: #131212;
  font-size: 15px;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  background: linear-gradient();
}
.cssactive {
  width: 100px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  background: linear-gradient();
  
  background-color: rgba(49, 139, 224, 0.726);
}
.namelogo {
  height: 70px;
  line-height: 70px;
  position: absolute;
  top: 40px;
  left: 17%;
  font-size: 32px;
  letter-spacing: 0.4em; 
  
  
  font-family: sans-serif;
 
}
.namelogoA{
  height: 70px;
  line-height: 70px;
  position: absolute;
  top: 80px;
  left: 17.5%;
  font-size: 22px;
  letter-spacing: 0.2em; 
 
  color: #8a868698 !important;
  font-family: sans-serif;

}
.heardbox_itema:hover {
  background-color: rgba(49, 139, 224, 0.726);
}
.tiao {
  width: 100%;
  height: 10px;
  background-color: rgba(49, 139, 224, 0.726);
}
</style>