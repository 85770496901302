import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};


const routes = [

    // {
    //     path: '/',
    //     name: '登录',
    //     iconClass: 'fa fa-leaf',
    //     hidden: true,
    //     component: () => import('@/views/login')
    // },
    {
        path: '/',
        name: '首页',
        hidden: false,
        iconClass: 'el-icon-document-copy',
        component: () => import('@/views/home'),
    }, {
        path: '/bianji',
        name: '编辑',
        hidden: false,
        iconClass: 'el-icon-document-copy',
        component: () => import('@/views/bianji'),

    },{
      path: '/guanyu',
      name: '练习',
      hidden: false,
      iconClass: 'el-icon-document-copy',
      component: () => import('@/views/index'),

  },{
    path:'/shopping',
    
    name :'商城',
    hidden: false,
    iconClass:'el-icon-document-copy',
    component:() =>import('@/views/shoppingcenter.vue')
  },{
    path:'/details',
    name :'详情',
    hidden: false,
    iconClass:'el-icon-document-copy',
    component:() =>import('@/views/details.vue')
  },{
    path:'/fuwu',
    name :'服务案例',
    hidden: false,
    iconClass:'el-icon-document-copy',
    component:() =>import('@/views/fuwu.vue')
  }

]


export default new Router({
    routes
})

